<template>
  <div class="content">
    <div class="top">
      <img src="../assets/0.jpg" alt="描述性文本" />
       </div>
    <div class="carousel" @touchstart="handleTouchStart" @touchmove="handleTouchMove" @touchend="handleTouchEnd">
    <div class="carousel-images" :style="{ transform: `translateX(-${currentIndex * 100}%)` }">
      <img class="cimages" v-for="(image, index) in images" :key="index" :src="image" />
    </div>
    </div>
   
    <div class="jianjie">
      <div class="fontsize1">저희 피지는 십여년간 무역을 해오면서 </div>
      <div class="fontsize1">다들 흔히하는 광고가 아닌 </div>
      <div class="fontsize1">오직 고객님들의 소개로 달려왔습니다</div>
      <div class="fontsize1">고객님들의 믿음에 상시 허의가 아닌</div>
      <div class="fontsize1">진실된 마음으로 임하겠습니다</div>
    </div>
    <div class="image-container">
    <img class="imagecon" :src="imageUrl" alt="描述性文本" />
    </div>
    <div class="textcenten">
      <div>
      <div class="textfont">피지의 서비스란</div>
      <br>
      <div class="text"><div class="textfont1">투명한 가격 - </div><div class="textfont2">알려드린 가격 이외에 추가 금액이 없음을 약속드립니다. </div></div>
      <br>
      <div class="text"><div class="textfont1">빠른 배송 - </div><div class="textfont2">주문 당일로 창고에 입고하여 물류비를 절약하는 동시에 빨리 받을 수 있도록 조치해 드립니다.</div></div>
      <br>
      <div class="text"><div class="textfont1">실시간 답변 - </div><div class="textfont2">1:1 팔로우 시스템으로 정해진 업무 시간내에만 답변드리는 업체와는 달리 확인되는 즉시 답변 및 주문됩니다.</div></div>
      <br>
      <div class="text"><div class="textfont1">가격비교 - </div><div class="textfont2">동일 제품은 더 저렴한 업체를 무료로 찾아드리는 서비스를 제공하고 있습니다.</div></div>
      <br>
      <div class="text"><div class="textfont1">빠른 불량교환 - </div><div class="textfont2">입고 당일로 검수하고 불량 제품은 합격일때까지 교환해 드립니다.</div></div>
      <br>
      <div class="text"><div class="textfont1">시상조사 - </div><div class="textfont2">고객님 별 일주일 한번 혹은 고객님의 수요에 따라 신상 및 제품을 찾아드립니다</div></div>
      <br>
      <div class="text"><div class="textfont1">물류비 최저가 - </div><div class="textfont2">인천 도착가는 물론 국내 배송비까지 여러 상황을 고려하여 알뜰하게 선사해 드립니다. </div></div>
      </div>
    </div>
    <div class="image-container">
      <img class="imagecon" src="../assets/9.jpg" alt="描述性文本" />
    </div>
    <div id="about">
      <div class="aboutcenten">
        <!-- <div class="m2">피지 에어전시</div> -->
        <div class="box1">
          <div class="col_1_of_1">
            <img class="aimg" src="../assets/3.jpg" alt="">
              <div class="desc">
              <div class="aboutfont1">시장사입</div>
              <div class="aboutfont">광저우/항저우/심천 시장 조사부터 물류, 가이드 까지 원스톱으로 제공해 드립니다</div>
            </div>
          </div>
          <div class="col_1_of_1">
            <img class="aimg" src="../assets/4.jpg" alt="" >
              <div class="desc">
              <div class="aboutfont1">1688 구매대행</div>
              <div class="aboutfont">구매부터 배송, 불량까지 모두 해결해드립니다</div>
              </div>
          </div>
          <!-- <div class="col_1_of_3 span_1_of_3">
          <img src="../assets/5.jpg" alt="" >
            <div class="desc">
            <div>VVIC 구매대행</div>
            <div>시장 사입은 물량이 부담이라 낱장으로 더 많은 제품을 빨리 초이스하고 싶다면 VVIC를 추천드립니다.</div>
            </div>
          </div> -->
        </div>
        <div class="box1">
          <div class="col_1_of_1">
            <img class="aimg" src="../assets/5.jpg" alt="">
              <div class="desc">
              <div class="aboutfont1">VVIC 구매대행</div>
              <div class="aboutfont">낱장으로 구매해도 모두 픽업해드립니다</div>
            </div>
          </div>
          <div class="col_1_of_1">
            <img class="aimg" src="../assets/6.jpg" alt="" >
              <div class="desc">
              <div class="aboutfont1">주문제작</div>
              <div class="aboutfont">OEM부터 주문제작까지 알뜰하게 만들어드립니다</div>
              </div>
          </div>
          <!-- <div class="col_1_of_3 span_1_of_3">
          <img src="../assets/5.jpg" alt="" >
            <div class="desc">
            <div>VVIC 구매대행</div>
            <div>시장 사입은 물량이 부담이라 낱장으로 더 많은 제품을 빨리 초이스하고 싶다면 VVIC를 추천드립니다.</div>
            </div>
          </div> -->
        </div>
        <div class="box1">
          <div class="col_1_of_1">
            <img class="aimg" src="../assets/7.jpg" alt="">
              <div class="desc">
              <div class="aboutfont1">물류대행</div>
              <div class="aboutfont">사입 지역과 제품의 특성에 따라 최저단가로 뽑아드립니다</div>
            </div>
          </div>
          <div class="col_1_of_1">
            <img class="aimg" src="../assets/8.jpg" alt="" >
              <div class="desc">
              <div class="aboutfont1">시장 가이드</div>
              <div class="aboutfont">의류 액세서리 신발 전자제품 원단 가방 등 다양한 제품을 수요에 따라 가이드해 드립니다</div>
              </div>
          </div>
          <!-- <div class="col_1_of_3 span_1_of_3">
          <img src="../assets/5.jpg" alt="" >
            <div class="desc">
            <div>VVIC 구매대행</div>
            <div>시장 사입은 물량이 부담이라 낱장으로 더 많은 제품을 빨리 초이스하고 싶다면 VVIC를 추천드립니다.</div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <div id="lianjie">
      <div class="aboutcenten1">
        <!-- <h2 class="m2">跳转</h2>
        <p class="m_4">This is Why You Will Use it :)</p> -->
        <div class="box2">
          <div class="col_1_of_3">
            <a href="https://main.m.taobao.com/?sprefer=sypc00"><img src="../assets/taobao.jpg" class="imgicon" alt="" >
            <div>
              <div>taobao</div>
              </div>
              </a>
          </div>
          <div class="col_1_of_3">
            <a href="https://www.1688.com "><img src="../assets/1688.jpg" class="imgicon" alt="" >
            <div>
              <div>1688</div>
              </div>
              </a>
          </div>
          <div class="col_1_of_3">
          <a href="https://www.vvic.com/gz"><img src="../assets/skw.jpg" class="imgicon" alt="" >
          <div>
            <div>vvic</div>
            </div>
            </a>
          </div>

        </div>
      </div>
    </div>
    <div id="lianxi">
      <div class="boxicon">
         <div class="lianxifont">
          중국전지역무역대행
         </div>
         <br>
        <div class="boxicon-item">
          <!-- <img src="../assets/kakao.jpg" class="imgicon" alt=""> -->
          위쳇: meiyue1115 / ruomu196125
        </div>
        <div class="boxicon-item">
          <!-- <img src="../assets/dh.jpg" class="imgicon" alt=""> -->
          연락처: 139-0230-2152 / 186-7689-2353
        </div>
      </div>
      <!-- <div class="lianxifont">
        <div>随时等候您的联系，我们将竭尽所能满足您的需求。</div>
      </div> -->
    </div>
  </div>
</template>

<script>

export default {
  beforeCreate () {
    
  },
  components: {

  },
  data(){
    return{
      images: [
        require('@/assets/1.jpg')
      ],
      imageUrl: require('@/assets/2.jpg'),
      currentIndex: 0,
      startX: 0,
      endX: 0,
      isTouching: false,
      carouselInterval:null
    }
  },
   mounted() {
    this.startCarousel();
  },
  beforeDestroy() {
    clearInterval(this.carouselInterval);
  },
  methods:{
    startCarousel() {
      this.carouselInterval = setInterval(() => {
        this.switchImage();
      }, 3000); // 每6秒切换一次图片
    },
    stopCarousel(){
        this.carouselInterval = null;
    },
     switchImage() {
      this.currentIndex = (this.currentIndex + 1) % this.images.length;
    },
    handleTouchStart(event) {
      this.startX = event.touches[0].clientX;
      this.isTouching = true;
    },
    handleTouchMove(event) {
      if (!this.isTouching) return;
      this.endX = event.touches[0].clientX;
    },
    handleTouchEnd() {
      if (!this.isTouching) return;
      const distance = this.endX - this.startX;
      if (Math.abs(distance) > 50) { // 判断滑动距离是否足够大
        if (distance > 0) {
          this.currentIndex--;
        } else {
          this.currentIndex++;
        }
        this.currentIndex = (this.currentIndex + this.images.length) % this.images.length; // 确保索引在有效范围内
      }
      this.isTouching = false;
    }
  }
  // 其他选项和代码
}
</script>
<style scoped>
@import '@/style/mynewpage.css'
</style>
